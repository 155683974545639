export function parseQueryString(value: string | string[] | undefined) {
  if (typeof value !== "string") {
    return;
  }
  return value;
}

export function parseArray<T>(input: T | T[]): T[] {
  if (Array.isArray(input)) return input;
  return [input];
}

export function sanitizeReadonlyArray<T>(
  array: Nullish<ReadonlyArray<Nullish<T>>>,
): ReadonlyArray<T> {
  if (!Array.isArray(array)) return [];

  return (array.filter(v => v !== null) as ReadonlyArray<T>) ?? [];
}

export function getUrlPathWithoutQuery(pathname: string) {
  if (typeof window === "undefined") return pathname.split("?")[0];
  const parsedUrl = new URL(window.location.href);
  return parsedUrl.pathname;
}

export function isSsr() {
  return typeof window === "undefined";
}

/**
 * For getting window safety by checking window is exists or not
 */
export function getWindow() {
  return typeof window === "undefined" ? null : window;
}

export function removeNullOrUndefinedInObject(object: Record<string, unknown>) {
  return Object.fromEntries(
    Object.entries(object).filter(([_, v]) => v !== null || v !== undefined),
  );
}

/**
 * Sorts an array of data items based on the order of a reference array, using the provided string keys.
 * Items that are not present in the reference array will be filtered out.
 **/
export function sortArrayByReferenceOrder<T, U>(parameters: {
  data: {
    array: readonly U[];
    idGetter: (item: U) => string;
  };
  order: {
    array: readonly T[];
    idGetter: (item: T) => string;
  };
}): U[] {
  const { data, order } = parameters;

  const lookup = data.array.reduce<Record<string, U>>((map, item) => {
    map[data.idGetter(item)] = item;
    return map;
  }, {});

  return order.array.map(item => lookup[order.idGetter(item)]).filter(Boolean);
}

export function titleCase(input?: string) {
  return input
    ?.toLowerCase()
    ?.replace(/\b\w/g, text => text.toUpperCase())
    .replace?.(/'\w/g, text => text.toLowerCase());
}
