import type { NetworkOptions as BaseNetworkOptions } from "@product/scmp-sdk/dist/types/lib/relay/network";
import type { FastifyRequest } from "fastify";
import type { IncomingMessage } from "http";
import setCookieParser from "set-cookie-parser";

import { config } from "shared/data";
import { SCMPAccessTokenCookiesKey } from "shared/lib/account";

import { isSsr } from "scmp-app/lib/utils";

export type NetworkOptions = {
  cookies?: FastifyRequest["cookies"];
  credentials: BaseNetworkOptions["urlOptions"]["credentials"];
  urlOptions?: BaseNetworkOptions["urlOptions"];
};

export const createNetworkOptions = ({
  cookies = {},
  credentials,
  urlOptions,
}: NetworkOptions): BaseNetworkOptions => ({
  authOptions: {
    allowEmptyToken: true,
    token: _ => cookies[SCMPAccessTokenCookiesKey] ?? "",
    async tokenRefreshPromise() {
      if (isSsr()) return "";
      const { refreshToken } = await import("shared/lib/account");
      const response = await refreshToken();
      if (!response) return "";
      const cookies = setCookieParser.parse(response as unknown as IncomingMessage);
      return cookies.find(cookie => cookie.name === SCMPAccessTokenCookiesKey)?.value ?? "";
    },
  },
  urlOptions: {
    credentials,
    headers: {
      apikey: config.graphql.contentService.apiKey,
    },
    method: "POST",
    url: config.graphql.contentService.host,
    ...urlOptions,
  },
});
