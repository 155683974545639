import { useAtomValue, useSetAtom } from "jotai";
import Script from "next/script";
import { type FunctionComponent, useCallback } from "react";

import { config } from "shared/data";

import { googleFundingChoicesAtom } from "scmp-app/components/advertisement/funding-choices/atoms";

import { magnitePrebidJSAtom } from "./atoms";

export const MagniteDemandManagerScripts: FunctionComponent = () => {
  const setMagnitePrebidJSAtom = useSetAtom(magnitePrebidJSAtom);
  const { isReady: isFundingChoiceReady } = useAtomValue(googleFundingChoicesAtom);

  const handleLoaded = useCallback(() => {
    setMagnitePrebidJSAtom({
      isReady: true,
    });
  }, [setMagnitePrebidJSAtom]);

  if (!isFundingChoiceReady) return null;

  return (
    <Script
      id="magnite-demand-manager-script"
      onError={handleLoaded}
      onLoad={handleLoaded}
      src={config.advertisement.magniteDemandManger.scriptUrl}
      strategy="afterInteractive"
    />
  );
};

MagniteDemandManagerScripts.displayName = "MagniteDemandManagerScripts";
