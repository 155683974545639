import { firebase } from "./firebase";
export const config = {
  account: {
    ampReaderId: process.env.NEXT_PUBLIC_AMP_READER_ID ?? "",
    application: process.env.NEXT_PUBLIC_APPLICATION ?? "",
    authCallbackUrl: process.env.NEXT_PUBLIC_AUTH_CALLBACK_URL ?? "",
    authDestination: process.env.NEXT_PUBLIC_SITE_URL ?? "",
    cookieDomain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN ?? "",
    debugLog: process.env.NODE_ENV !== "production",
    recaptchaContainerId: process.env.NEXT_PUBLIC_RECAPTCHA_CONTAINER_ID ?? "",
    recaptchaSiteKey: process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY ?? "",
    registerCallbackUrl: process.env.NEXT_PUBLIC_REGISTER_CALLBACK_URL ?? "",
    scmpAccountHost: process.env.NEXT_PUBLIC_SCMP_ACCOUNT_HOST ?? "",
    sendWelcomeEmail: process.env.NEXT_PUBLIC_SEND_WELCOME_EMAIL === "true",
    userPicPrefix: process.env.NEXT_PUBLIC_USER_PROFILE_PIC_PREFIX ?? ""
  },
  advertisement: {
    amazonPublisherService: {
      bidTimeout: 1.5e3,
      publisherId: "6480e4f8-95c9-4189-ad8f-2c8a76bcf0ab"
    },
    campaignConfigId: "scmp_pwa_advert_campaign",
    configId: process.env.NEXT_PUBLIC_ADVERTISEMENT_CONFIG_ID ?? "",
    failsafeTimeout: Number.parseInt(process.env.NEXT_PUBLIC_ADVERTISEMENT_FAILSAFE_TIMEOUT ?? "2000"),
    googlePublisherTag: {
      adUnitPrefix: process.env.NEXT_PUBLIC_GPT_AD_UNIT_PREFIX ?? "",
      networkId: "8134"
    },
    magniteDemandManger: {
      scriptUrl: process.env.NEXT_PUBLIC_MAGNITE_DEMAND_MANAGER_SCRIPT_URL ?? ""
    },
    prebid: {
      googleAnalyticsProfileId: process.env.NEXT_PUBLIC_PREBID_GA_PROFILE_ID
    },
    viewabilityService: {
      apiKey: process.env.NEXT_PUBLIC_ADVERTISEMENT_VIEWABILITY_SERVICE_API_KEY ?? "",
      url: process.env.NEXT_PUBLIC_ADVERTISEMENT_VIEWABILITY_SERVICE_URL ?? ""
    }
  },
  app: {
    android: {
      name: "SCMP",
      package: "com.scmp.newspulse" /* Cspell: disable-line */
    },
    ios: {
      name: "SCMP",
      storeId: "623201967"
    }
  },
  corporateSubscriber: {
    host: process.env.NEXT_PUBLIC_CORPORATE_SUBSCRIBER_HOST ?? ""
  },
  date: {
    defaultFormat: "d MMM yyyy - h:mma",
    defaultTimeZone: "Asia/Hong_Kong"
  },
  domain: {
    scmp: process.env.NEXT_PUBLIC_DOMAIN_SCMP ?? ""
  },
  drupal: {
    host: process.env.NEXT_PUBLIC_DRUPAL_HOST ?? ""
  },
  elasticsearch: {
    apiKey: process.env.NEXT_PUBLIC_ELASTICSEARCH_API_KEY ?? "",
    host: process.env.NEXT_PUBLIC_ELASTICSEARCH_HOST ?? ""
  },
  firebase,
  general: {
    aiEngineFallbackHost: process.env.NEXT_PUBLIC_AI_ENGINE_FALLBACK_HOST ?? "",
    aiEngineHost: process.env.NEXT_PUBLIC_AI_ENGINE_HOST ?? "",
    canonicalBaseUrl: process.env.NEXT_PUBLIC_CANONICAL_BASE_URL ?? "",
    cdn: {
      enabled: process.env.NEXT_PUBLIC_USE_CDN === "true",
      host: process.env.NEXT_PUBLIC_OSS_HOST ?? ""
    },
    commitSha: process.env.NEXT_PUBLIC_COMMIT_SHA ?? "",
    env: process.env.NEXT_PUBLIC_ENV_SLUG ?? "",
    readingHistoryHost: process.env.NEXT_PUBLIC_READING_HISTORY_HOST ?? "",
    reverseProxyHost: process.env.NEXT_PUBLIC_REVERSE_PROXY_HOST ?? "",
    siteUrl: process.env.NEXT_PUBLIC_SITE_URL ?? "",
    userFollowHost: process.env.NEXT_PUBLIC_USER_FOLLOW_HOST ?? "",
    userTypeHost: process.env.NEXT_PUBLIC_USER_TYPE_HOST ?? ""
  },
  google: {
    clientId: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID ?? ""
  },
  googleAnalytics: {
    measurementId: process.env.NEXT_PUBLIC_GA4_MEASUREMENT_ID ?? "",
    profileId: process.env.NEXT_PUBLIC_GA_PROFILE_ID ?? ""
  },
  googleTagManager: {
    containerId: process.env.NEXT_PUBLIC_GTM_CONTAINER_ID ?? ""
  },
  graphql: {
    contentService: {
      apiKey: process.env.NEXT_PUBLIC_CONTENTSERVICE_API_KEY ?? "",
      host: process.env.NEXT_PUBLIC_CONTENTSERVICE_HOST ?? "",
      serverHost: process.env.NEXT_PUBLIC_SERVER_CONTENTSERVICE_HOST ?? ""
    },
    drupal: {
      apiKey: process.env.NEXT_PUBLIC_DRUPAL_API_KEY ?? "",
      host: process.env.NEXT_PUBLIC_DRUPAL_GRAPHQL_HOST ?? ""
    },
    userService: {
      apiKey: process.env.NEXT_PUBLIC_USERSERVICE_API_KEY ?? "",
      host: process.env.NEXT_PUBLIC_USERSERVICE_HOST ?? ""
    }
  },
  locationService: {
    apiKey: process.env.NEXT_PUBLIC_LOCATION_SERVICE_API_KEY ?? "",
    url: process.env.NEXT_PUBLIC_LOCATION_SERVICE_URL ?? ""
  },
  navigatorInfo: {
    apiKey: process.env.NEXT_PUBLIC_NAVIGATOR_INFO_API_KEY ?? "",
    url: process.env.NEXT_PUBLIC_NAVIGATOR_INFO_URL ?? ""
  },
  rosetta: {
    apiGatewayHost: process.env.NEXT_PUBLIC_ROSETTA_API_GATEWAY_HOST ?? "",
    contentService: {
      apiKey: process.env.NEXT_PUBLIC_ROSETTA_CONTENTSERVICE_API_KEY ?? "",
      environment: process.env.NEXT_PUBLIC_ROSETTA_CONTENTSERVICE_ENVIRONMENT ?? ""
    },
    environment: process.env.NEXT_PUBLIC_ROSETTA_ENVIRONMENT ?? "",
    piano: {
      apiUri: process.env.NEXT_PUBLIC_ROSETTA_PIANO_API_URL ?? "",
      applicationId: process.env.NEXT_PUBLIC_ROSETTA_PIANO_APPLICATION_ID ?? "",
      environment: process.env.NEXT_PUBLIC_ROSETTA_PIANO_ENVIRONMENT ?? ""
    },
    subscribeHost: process.env.NEXT_PUBLIC_ROSETTA_HOST ?? "",
    subscribeTemplateHost: process.env.NEXT_PUBLIC_SUBSCRIPTION_TEMPLATE ?? ""
  },
  sentry: {
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN ?? "",
    enableTracing: process.env.NEXT_PUBLIC_SENTRY_ENABLE_TRACING ?? "false"
  },
  social: {
    api: {
      host: process.env.NEXT_PUBLIC_SOCIAL_API_HOST ?? ""
    },
    facebook: {
      appId: "282931841805811",
      likeUrl: "https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fwww.facebook.com%2Fscmp%2F&width=86&layout=button_count&action=like&size=small&share=false&height=21&appId=282931841805811"
    },
    twitter: {
      scmp: {
        accountName: "SCMPNews"
      },
      style: {
        accountName: "SCMP_Style"
      }
    }
  }
};