import { atom } from "jotai";

export type BlurbCardHighlightSection = "daily-pulse" | "news-agenda" | "scmp-plus-highlights";

export type PlusPopupState = {
  currentBlurbCardHighlightSection?: BlurbCardHighlightSection;
  fromDrawerUrl?: string;
  isShowArticlePopup: boolean;
  isShowBlurbPopup: boolean;
  isShowDetailListingPopup: boolean;
  originalUrl?: string;
};

export const plusPopupAtom = atom<PlusPopupState>({
  isShowArticlePopup: false,
  isShowBlurbPopup: false,
  isShowDetailListingPopup: false,
});
