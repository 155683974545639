import { NoSsr } from "@mui/base";
import type { PageProps } from "next";
import type { FunctionComponent, ReactNode } from "react";

import { IdleQueue } from "scmp-app/components/common/idle-queue";
import { GoogleOneTapLoginContainer } from "scmp-app/components/google-one-tap-login";
import { DynamicPlusArticlePopup } from "scmp-app/components/plus/article-popup/dynamics";
import { DynamicPlusBlurbPopup } from "scmp-app/components/plus/blurb-popup/dynamics";
import { DynamicPlusDetailedListingPopup } from "scmp-app/components/plus/detailed-listing-popup/dynamics";
import { TrendingNavBar } from "scmp-app/components/trending-nav-bar";
import { PlusLearnMorePlaceholder } from "scmp-app/pages/plus/learnmore/placeholder";
import { PlusLearnMoreSuspense } from "scmp-app/pages/plus/learnmore/suspense";

import {
  DynamicAppBar,
  DynamicContentReactionActionRedirect,
  DynamicHamburgerMenu,
  DynamicLoginDialog,
  DynamicNewsTicker,
  DynamicPlusPaywallHookRunner,
  DynamicPopupContainer,
  DynamicSimpleHooksRunner,
} from "./dynamics";

export type Props = {
  children: ReactNode;
  pageProps?: PageProps;
};

/**
 * This component is for placing application specific features and also the application header bar
 */
export const AppInitializer: FunctionComponent<Props> = ({ children, pageProps }) => {
  const application = pageProps?.appInitializerConfiguration?.application ?? "scmp";
  const theme = pageProps?.appInitializerConfiguration?.theme ?? "default";

  switch (application) {
    case "plus":
      return (
        <>
          <NoSsr>
            <DynamicSimpleHooksRunner />
            <DynamicLoginDialog />
            <DynamicPlusBlurbPopup />
            <DynamicPlusPaywallHookRunner>
              <DynamicPlusArticlePopup />
              <DynamicPlusDetailedListingPopup />
            </DynamicPlusPaywallHookRunner>
          </NoSsr>
          <DynamicAppBar pageProps={pageProps}>
            <NoSsr>
              <IdleQueue>
                <DynamicNewsTicker appBarVariant={pageProps?.appBarConfiguration?.variant} />
              </IdleQueue>
            </NoSsr>
          </DynamicAppBar>
          <PlusLearnMoreSuspense fallback={<PlusLearnMorePlaceholder />}>
            {children}
          </PlusLearnMoreSuspense>
        </>
      );
    case "posties":
      return (
        <>
          <DynamicHamburgerMenu variant={pageProps?.appBarConfiguration?.variant} />
          <NoSsr>
            <DynamicSimpleHooksRunner />
            <DynamicLoginDialog />
          </NoSsr>
          <DynamicAppBar pageProps={pageProps} />
          {children}
        </>
      );
    default:
      switch (theme) {
        case "print-article":
          return (
            <>
              <NoSsr>
                <DynamicSimpleHooksRunner />
              </NoSsr>
              {children}
            </>
          );
        default:
          return (
            <>
              <IdleQueue>
                <DynamicPopupContainer />
              </IdleQueue>
              <DynamicHamburgerMenu variant={pageProps?.appBarConfiguration?.variant} />
              <NoSsr>
                <DynamicSimpleHooksRunner />
                <DynamicContentReactionActionRedirect />
                <DynamicLoginDialog />
              </NoSsr>
              <DynamicAppBar pageProps={pageProps}>
                {pageProps?.appInitializerConfiguration?.hasTrendingNavBar && <TrendingNavBar />}
                <NoSsr>
                  <IdleQueue>
                    <GoogleOneTapLoginContainer />
                    <DynamicNewsTicker appBarVariant={pageProps?.appBarConfiguration?.variant} />
                  </IdleQueue>
                </NoSsr>
              </DynamicAppBar>
              {children}
            </>
          );
      }
  }
};

AppInitializer.displayName = "AppInitializer";
