import { safeSetLocalStorage } from "@product/scmp-sdk";
import { useAtomValue } from "jotai";
import { useRouter } from "next/router";
import type { FunctionComponent, SuspenseProps } from "react";
import { useEffect, useState } from "react";

import { usePlusBlurbPopup } from "scmp-app/components/plus/blurb-popup/hooks";
import { rosettaAtom } from "scmp-app/lib/rosetta";
import { pathname as plusLearnMorePathname } from "scmp-app/pages/plus/learnmore/index";

import { SkipPlusLearnMorePlaceholderLocalStorageKey } from "./consts";

export const PlusLearnMoreSuspense: FunctionComponent<SuspenseProps> = ({ children, ...props }) => {
  const asyncRosettaState = useAtomValue(rosettaAtom);
  const hasScmpPlusAccessRight = asyncRosettaState?.result?.hasScmpPlusAccessRight ?? false;
  const router = useRouter();
  const { checkPlusHomepagePathOnly } = usePlusBlurbPopup();

  const skipPlusLearnMorePlaceholder =
    typeof window === "undefined"
      ? null
      : window.localStorage.getItem(SkipPlusLearnMorePlaceholderLocalStorageKey);

  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
  }, []);
  useEffect(() => {
    if (hasScmpPlusAccessRight && skipPlusLearnMorePlaceholder !== "1") {
      safeSetLocalStorage(SkipPlusLearnMorePlaceholderLocalStorageKey, "1");
    }
  }, [hasScmpPlusAccessRight, skipPlusLearnMorePlaceholder]);

  if (!isClient) return null;

  if (
    (skipPlusLearnMorePlaceholder ?? plusLearnMorePathname === router.pathname) ||
    checkPlusHomepagePathOnly
  ) {
    return children;
  }
  if (asyncRosettaState?.status !== "success" || !hasScmpPlusAccessRight) {
    return props.fallback;
  }
  return children;
};

PlusLearnMoreSuspense.displayName = "PlusLearnMoreSuspense";
