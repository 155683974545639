import styled from "@emotion/styled";
import { fontRoboto } from "@product/scmp-sdk";
import { Swiper, SwiperSlide } from "swiper/react";

import { BaseLink } from "scmp-app/components/common/base-link";

export const StyledSwiperSlide = styled(SwiperSlide)`
  display: flex;
`;

export const StyledSwiper = styled(Swiper)`
  ${StyledSwiperSlide} {
    inline-size: auto;
    text-size-adjust: 100%;
  }
`;

export const ItemContainer = styled.div``;

export const Item = styled(BaseLink)`
  color: #333333;
  font-size: 12px;
  font-family: ${fontRoboto};
  line-height: 14px;

  :hover {
    color: #2957b5;
  }
`;

export const Trending = styled.span`
  margin-inline-end: 12px;

  color: #eb4254;
  font-weight: 700;
`;

export const Divider = styled.span`
  content: "|";

  margin-inline: 12px;

  color: #33333333;
`;
