import { type FunctionComponent } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";

import { BaseLinkContextProvider } from "scmp-app/components/common/base-link/context";
import type { trendingNavBarSwiperQueueQuery } from "scmp-app/queries/__generated__/trendingNavBarSwiperQueueQuery.graphql";

import { Divider, Item, StyledSwiper, StyledSwiperSlide } from "./styles";
type Props = {
  className?: string;
  edition: string;
};

type PwaSubnavMenuConfigItem = {
  title: string;
  url: string;
};

export const TrendingNavBarSwiper: FunctionComponent<Props> = ({ className, edition }) => {
  const data = useLazyLoadQuery<trendingNavBarSwiperQueueQuery>(
    graphql`
      query trendingNavBarSwiperQueueQuery {
        appConfig(filter: { entityId: "pwa_subnav_menu" }) {
          json
        }
      }
    `,
    {},
    { fetchPolicy: "store-or-network" },
  );

  const subNavMenuConfig = data.appConfig?.json as Record<string, PwaSubnavMenuConfigItem[]>;
  if (!subNavMenuConfig || !subNavMenuConfig[edition]) return null;

  const items = subNavMenuConfig[edition];
  return (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: `oneline_menu_trending_${edition}`,
      }}
    >
      <StyledSwiper
        className={className}
        cssMode={false}
        pagination={false}
        slidesOffsetAfter={20}
        slidesPerView="auto"
      >
        {items.map((item, index) => (
          <StyledSwiperSlide key={index}>
            <Item anchorProps={{ title: item.title }} as={item.url} pathname={item.url}>
              {item.title}
            </Item>
            {index !== items.length - 1 && <Divider>|</Divider>}
          </StyledSwiperSlide>
        ))}
      </StyledSwiper>
    </BaseLinkContextProvider>
  );
};

TrendingNavBarSwiper.displayName = "TrendingNavBarSwiper";
