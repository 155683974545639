import { PageType } from "@product/scmp-sdk";

import * as data from "shared/data";

import type { AppBarVariant } from "scmp-app/components/app-bar/types";
import type { Application } from "scmp-app/components/app-initializer/helpers";
import type { ArticleRenderType } from "scmp-app/components/article/article-render/types";
import { SubSectionList as StyleSubSectionList } from "scmp-app/components/magazines-style/consts";
import { Edition } from "scmp-app/lib/edition/data"; // This is for preventing server restart because of changing hooks
import { page as article, pathname as articlePathname } from "scmp-app/pages/article";
import { page as author, pathname as authorPathname } from "scmp-app/pages/author";
import { page as authors, pathname as authorsPathname } from "scmp-app/pages/authors";
import { page as home } from "scmp-app/pages/home";
import { page as live, pathname as livePathname } from "scmp-app/pages/live";
import { page as menu, pathname as menuPathname } from "scmp-app/pages/menu";
import { page as newsletters, pathname as newslettersPathname } from "scmp-app/pages/newsletters";
import { page as page, pathname as pagePathname } from "scmp-app/pages/page";
import { page as plus, pathname as plusPathname } from "scmp-app/pages/plus";
import { page as plusAgenda, pathname as plusAgendaPathname } from "scmp-app/pages/plus/agenda";
import { page as plusArchive, pathname as plusArchivePathname } from "scmp-app/pages/plus/archive";
import {
  page as plusLearnMore,
  pathname as plusLearnMorePathname,
} from "scmp-app/pages/plus/learnmore";
import { page as plusSearch, pathname as plusSearchPathname } from "scmp-app/pages/plus/search";
import { page as podcast, pathname as podcastPathname } from "scmp-app/pages/podcast";
import { page as podcasts, pathname as podcastsPathname } from "scmp-app/pages/podcasts";
import { page as posties, pathname as postiesPathname } from "scmp-app/pages/posties";
import { page as posties404, pathname as posties404Pathname } from "scmp-app/pages/posties/404";
import {
  page as postiesEvent,
  pathname as postiesEventPathname,
} from "scmp-app/pages/posties/event";
import {
  page as postiesEvents,
  pathname as postiesEventsPathname,
} from "scmp-app/pages/posties/events";
import { page as postiesKids, pathname as postiesKidsPathname } from "scmp-app/pages/posties/kids";
import {
  page as postiesParents,
  pathname as postiesParentsPathname,
} from "scmp-app/pages/posties/parents";
import {
  page as postiesSection,
  pathname as postiesSectionPathname,
} from "scmp-app/pages/posties/section-index";
import {
  page as postiesVideo,
  pathname as postiesVideoPathname,
} from "scmp-app/pages/posties/video";
import {
  page as printArticle,
  pathname as printArticlePathname,
} from "scmp-app/pages/print-article";
import { page as search, pathname as searchPathname } from "scmp-app/pages/search";
import { page as section, pathname as sectionPathname } from "scmp-app/pages/section";
import type { Variant as SectionVariant } from "scmp-app/pages/section/enums";
import { getSectionRelatedSectionsQueueName } from "scmp-app/pages/section/helpers";
import { page as specialIndex } from "scmp-app/pages/special-index";
import {
  page as storyCard,
  styleStaticPathname as storyStyleStaticPathname,
} from "scmp-app/pages/story-card";
import { page as subSection, pathname as subSectionPathname } from "scmp-app/pages/sub-section";
import { page as topic, pathname as topicPathname } from "scmp-app/pages/topic";
import {
  page as topicCollection,
  pathname as topicCollectionPathname,
} from "scmp-app/pages/topic-collection";
import { page as topics, pathname as topicsPathname } from "scmp-app/pages/topics";

const pages = {
  article,
  author,
  authors,
  home,
  live,
  menu,
  newsletters,
  page,
  plus,
  plusAgenda,
  plusArchive,
  plusLearnMore,
  plusSearch,
  podcast,
  podcasts,
  posties,
  posties404,
  postiesEvent,
  postiesEvents,
  postiesKids,
  postiesParents,
  postiesSection,
  postiesVideo,
  printArticle,
  search,
  section,
  specialIndex,
  storyCard,
  subSection,
  topic,
  topicCollection,
  topics,
};

type StaticRouteFunction = (context: {
  params: Record<string, string>;
  query: Record<string, string>;
}) => {
  asPath?: string;
  cacheMaxAge?: number;
  pathname: string;
  query?: Record<string, string>;
};

const statics: Record<string, StaticRouteFunction> = {
  "/": () => ({
    ...pages.home.route({ edition: Edition.International, slide: "main" }),
    cacheMaxAge: 60,
  }),
  "/asia": () => ({
    ...pages.home.route({ edition: Edition.Asia, slide: "main" }),
    cacheMaxAge: 60,
  }),
  "/explained": () =>
    pages.specialIndex.route({
      pageType: PageType.Explained,
      queueName: `section_top_${data.section.explained.entityId}`,
    }),
  "/hk": () => ({
    ...pages.home.route({ edition: Edition.HongKong, slide: "main" }),
    cacheMaxAge: 60,
  }),
  // Treat drupal video sections as sections since content service does not include video section
  "/scmp-films": () =>
    pages.specialIndex.route({
      pageType: PageType.ScmpFilms,
      queueName: `video_section_picks_${data.section.scmpFilms.entityId}`,
    }),
  "/scmp-spotlight": () =>
    pages.specialIndex.route({ pageType: PageType.Spotlight, queueName: "scmp_spotlight_top" }),
  "/series": () =>
    pages.specialIndex.route({ pageType: PageType.Series, queueName: "scmp_series_top" }),
  [`${searchPathname}/:value?`]: context =>
    pages.search.route({ value: context.query.value ?? context.params.value }),
  ...StyleSubSectionList.reduce<Record<string, StaticRouteFunction>>(
    (staticsRoutes, subSection) => {
      staticsRoutes[`${storyStyleStaticPathname}/${subSection}`] = () => ({
        ...pages.storyCard.route({ subSection }),
        cacheMaxAge: 60,
      });
      return staticsRoutes;
    },
    {},
  ),
  "/talking-post": () =>
    pages.specialIndex.route({
      pageType: PageType.TalkingPost,
      queueName: `video_section_picks_${data.section.talkingPost.entityId}`,
    }),
  [`${plusSearchPathname}/:value?`]: context =>
    pages.plusSearch.route({
      nodeId: context.query.nodeId,
      value: context.query.value ?? context.params.value,
    }),
  [authorsPathname]: context => pages.authors.route({ char: context.query.char }),
  [livePathname]: () => ({ ...pages.live.route({}), cacheMaxAge: 60 }),
  [menuPathname]: () => pages.menu.route({}),
  [newslettersPathname]: () => pages.newsletters.route({}),
  [plusAgendaPathname]: context => ({
    ...pages.plusAgenda.route({
      articleId: context.query.articleId,
      entityId: context.query.entityId,
      nodeId: context.query.nodeId,
      startDate: context.query.startDate,
    }),
    cacheMaxAge: 60,
  }),
  [plusArchivePathname]: context => ({
    ...pages.plusArchive.route({
      nodeId: context.query.nodeId,
    }),
    cacheMaxAge: 60,
  }),
  [plusLearnMorePathname]: () => pages.plusLearnMore.route({}),
  [plusPathname]: context => ({
    ...pages.plus.route({
      articleId: context.query.articleId,
      display: context.query.display,
      nodeId: context.query.nodeId,
    }),
    cacheMaxAge: 60,
  }),
  [podcastsPathname]: () => pages.podcasts.route({}),
  [posties404Pathname]: () => pages.posties404.route(),
  [postiesEventsPathname]: () => ({ ...pages.postiesEvents.route({}), cacheMaxAge: 60 }),
  [postiesKidsPathname]: () => ({ ...pages.postiesKids.route({}), cacheMaxAge: 60 }),
  [postiesParentsPathname]: () => ({ ...pages.postiesParents.route({}), cacheMaxAge: 60 }),
  [postiesPathname]: () => pages.posties.route({}),
  [postiesVideoPathname]: () => ({ ...pages.postiesVideo.route({}), cacheMaxAge: 60 }),
  [topicsPathname]: context => pages.topics.route({ char: context.query.char }),
};

export function isStaticRoute(url: string) {
  return Object.keys(routes.statics).some(staticRoute => staticRoute.startsWith(url));
}

type DynamicRouteFunctionContext = {
  appBarVariant?: AppBarVariant;
  application?: Application;
  articleRenderType?: ArticleRenderType;
  asPath?: string;
  entityUuid?: string;
  fullSectionPath?: string[];
  isBot?: string;
  matchedPrefix: OrUndefined<string>;
  name?: null | string;
  nodeId?: null | string;
  params: Record<string, string>;
  parentSection?: null | string;
  query: Record<string, string>;
  sectionVariant?: SectionVariant;
  startDate?: string;
};

type DynamicRouteFunctionResult = {
  pathname: string;
  query?: Record<string, string>;
};

type DynamicRouteFunction = {
  (entityId: string, context?: DynamicRouteFunctionContext): DynamicRouteFunctionResult;
};

interface DynamicRoute {
  cacheMaxAge?: number;
  pathnames: string[];
  prefix?: string[];
  route: DynamicRouteFunction;
}

const dynamics: Record<string, DynamicRoute> = {
  Article: {
    pathnames: [articlePathname, printArticlePathname],
    prefix: [...pages.printArticle.prefix],
    route(entityId, context) {
      const matchedPrefix = context?.matchedPrefix;
      const commentID = context?.query.commentID;
      const entityUuid = context?.entityUuid ?? "";
      const appBarVariant = context?.appBarVariant;
      const application = context?.application;
      const isBot = context?.isBot;
      const renderType = context?.articleRenderType;
      const share = context?.query.share ?? "";

      return matchedPrefix && pages.printArticle.prefix.includes(matchedPrefix)
        ? pages.printArticle.route({ entityId })
        : pages.article.route({
            appBarVariant,
            application,
            commentID,
            entityId,
            entityUuid,
            isBot,
            renderType,
            share,
          });
    },
  },
  Author: {
    cacheMaxAge: 60,
    pathnames: [authorPathname],
    route(entityId) {
      return pages.author.route({ entityId });
    },
  },
  Collection: {
    cacheMaxAge: 60,
    pathnames: [topicCollectionPathname],
    route(entityId) {
      return pages.topicCollection.route({ entityId });
    },
  },
  NewsAgenda: {
    pathnames: [plusAgendaPathname],
    route(entityId, context) {
      return pages.plusAgenda.route({
        entityId,
        startDate: context?.startDate,
      });
    },
  },
  Newsletter: {
    pathnames: [newslettersPathname, "/newsletter"],
    route(entityId) {
      return pages.newsletters.route({ entityId });
    },
  },
  Page: {
    pathnames: [pagePathname],
    route(entityId, context) {
      const appBarVariant = context?.appBarVariant;
      const application = context?.application;
      return pages.page.route({ appBarVariant, application, entityId });
    },
  },
  Plus: {
    cacheMaxAge: 60,
    pathnames: [plusPathname],
    route(entityId, context) {
      return pages.plus.route({
        articleId: entityId,
        display: context?.query.display,
        nodeId: context?.nodeId ?? "",
      });
    },
  },
  Podcast: {
    pathnames: [podcastPathname],
    route(entityId) {
      return pages.podcast.route({ entityId });
    },
  },
  PodcastSection: {
    pathnames: [podcastPathname],
    route(entityId) {
      return pages.podcast.route({ entityId });
    },
  },
  PostiesEvent: {
    pathnames: [postiesEventPathname],
    route(entityId) {
      return pages.postiesEvent.route({ entityId });
    },
  },
  Section: {
    cacheMaxAge: 60,
    pathnames: [sectionPathname, postiesSectionPathname],
    route(entityId, context) {
      const application = context?.application;
      const appBarVariant = context?.appBarVariant;
      const name = context?.name ?? "";
      const parentSection = context?.parentSection ?? "";
      const fullSectionPath = context?.fullSectionPath ?? [];
      const sectionVariant = context?.sectionVariant;

      if (parentSection === data.section.posties.read.entityUuid) {
        return pages.postiesSection.route({ application, entityId, name });
      }

      return pages.section.route({
        appBarVariant,
        application,
        entityId,
        name,
        parentSection,
        relatedSectionsQueueName: getSectionRelatedSectionsQueueName(fullSectionPath),
        sectionVariant,
      });
    },
  },
  SubSection: {
    cacheMaxAge: 60,
    pathnames: [subSectionPathname],
    route: (entityId, context) => {
      const appBarVariant = context?.appBarVariant;
      const name = context?.name ?? "";
      const parentSection = context?.parentSection ?? "";
      const fullSectionPath = context?.fullSectionPath ?? [];
      const sectionVariant = context?.sectionVariant;
      const asPath = context?.asPath;

      return pages.subSection.route({
        appBarVariant,
        asPath,
        entityId,
        name,
        parentSection,
        relatedSectionsQueueName: getSectionRelatedSectionsQueueName(fullSectionPath),
        sectionVariant,
      });
    },
  },
  Topic: {
    cacheMaxAge: 60,
    pathnames: [topicPathname],
    route(entityId) {
      return pages.topic.route({ entityId });
    },
  },
  Video: {
    cacheMaxAge: 60,
    pathnames: [postiesVideoPathname],
    route(entityId) {
      return pages.postiesVideo.route({ entityId });
    },
  },
};

// These redirects are scoped to the pwa application level for server side redirects.
// Client side should not need to handle these as there should not be any route define with old path.
const applicationRedirects = {
  "/frontpage/hk": "/hk",
  "/frontpage/international": "/",
  "/home": "/",
  "/newsletter": "/newsletters",
  "/us": "/",
};

// These redirects are defined in the drupal.
// Client side should route these paths to server to resolve for the actual redirect value.
const drupalRedirects = [
  "/topics/coronavirus-outbreak",
  "/topics/coronavirus-pandemic",
  "/topics/explorers-tomorrow",
  "/comment/harrys-view",
];

export function isDrupalRedirect(url: string) {
  return drupalRedirects.some(redirect => url.startsWith(redirect));
}

// These patterns are handled by different applications with routing at ingress
const internals = [
  "/sport/racing",
  "/infographic",
  "/photos",
  "/video",
  "/cooking",
  "/yp",
  "/china-internet-report",
  "/knowledge",
];

// These patterns that belongs to external are specially handled by pwa internally
const excludedInternals = [
  "/knowledge/us-china-relations",
  "/knowledge/china-macro-economy",
  "/infographics",
];

export function isInternalDomainRoute(url: string) {
  return (
    internals.some(internal => url.startsWith(internal)) &&
    !excludedInternals.some(excluded => url.startsWith(excluded))
  );
}

const rejects = ["/inkstone"];

export function isEnabledClientRoute(url: string) {
  if (url.startsWith("/plus")) return true;

  // Turn off all the client side routing by default in case for v1 page routing
  return false;
}

export const routes = {
  applicationRedirects,
  drupalRedirects,
  dynamics,
  excludedInternals,
  internals,
  rejects,
  statics,
};
