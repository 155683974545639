import { useAtom, useAtomValue } from "jotai";
import { usePathname, useSearchParams } from "next/navigation";
import { useCallback, useMemo } from "react";

import type { BlurbCardHighlightSection } from "scmp-app/components/plus/atoms";
import { plusPopupAtom } from "scmp-app/components/plus/atoms";
import { QueryParameterName } from "scmp-app/components/plus/detailed-listing-popup/navigation";
import { rosettaAtom } from "scmp-app/lib/rosetta";
import { pathname as homepagePathname } from "scmp-app/pages/plus";

export const usePlusBlurbPopup = () => {
  const [_, setPlusPopupState] = useAtom(plusPopupAtom);
  const pathname = usePathname();
  const searchParameters = useSearchParams();
  const handleOpen = useCallback(
    (section: BlurbCardHighlightSection) => {
      setPlusPopupState(current => ({
        ...current,
        currentBlurbCardHighlightSection: section,
        isShowBlurbPopup: true,
      }));
    },
    [setPlusPopupState],
  );

  const handleClose = useCallback(() => {
    setPlusPopupState(current => ({
      ...current,
      isShowBlurbPopup: false,
    }));
  }, [setPlusPopupState]);

  const asyncRosettaState = useAtomValue(rosettaAtom);
  const hasScmpPlusAccessRight = useMemo(
    () => asyncRosettaState?.result?.hasScmpPlusAccessRight ?? false,
    [asyncRosettaState?.result?.hasScmpPlusAccessRight],
  );
  const isSubscriber = useMemo(
    () => asyncRosettaState?.result?.user?.isSCMPSubscriber ?? false,
    [asyncRosettaState?.result?.user?.isSCMPSubscriber],
  );
  const checkPlusHomepagePathOnly = useMemo(
    () => pathname === homepagePathname && !searchParameters.get(QueryParameterName),
    [pathname, searchParameters],
  );

  return {
    checkPlusHomepagePathOnly,
    handleClose,
    handleOpen,
    hasScmpPlusAccessRight,
    isSubscriber,
  };
};
